.big-tech-s3-multimedia-series {
  &.big-tech-s3-theme {
    @include link($color: $cigi-darkest-grey, $hover-color: $bigtech-teal);

    // sass-lint:disable-block class-name-format
    .wagtail-userbar__item {
      a {
        color: $cigi-medium-light-grey;
      }
    }

    .topics {
      @include link($color: $cigi-text-grey, $hover-color: $bigtech-teal);
    }

    .big-tech-hero {
      margin-bottom: 0;
    }

    .big-tech-mm-series-hero {
      @include media-breakpoint-up(md) {
        padding-bottom: 4em;
      }
      padding-bottom: 2em;
      text-align: center;

      img {
        max-width: 600px;
      }

      .season-tagline {
        color: $bigtech-teal;
        font-size: 1rem;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 1.5rem;
        text-transform: uppercase;
      }

      .description {
        font-style: italic;
        margin-top: 2rem;
      }

      .social-share-list {
        i {
          font-size: 1em;
          transition: color 0.2s ease;

          &:hover {
            color: $bigtech-teal;
          }
        }
      }

      .subscribe-label {
        color: $bigtech-light-grey;
      }

      .subscribe-links {
        align-items: center;
        display: flex;
        height: 50px;
        justify-content: center;
        margin-top: 1rem;

        .slash {
          @include media-breakpoint-up(md) {
            height: 50px;
            margin: 0 25px 0 28px;
          }
          border-right: 1px solid $black-alpha-40;
          height: 35px;
          margin: 0 10px;
          transform: rotate(15deg);
          width: 5px;
        }

        .apple {
          width: 150px;
        }

        .spotify {
          width: 100px;
        }

        .pocket-casts {
          width: 140px;
        }
      }
    }

    .latest-episode {
      padding: 3em 0;

      h2 {
        color: $bigtech-teal;
      }

      h3 {
        font-size: 1.125em;
        font-style: italic;
        line-height: 1;
        margin-bottom: 0.5rem;

        div {
          &:not(.block-guest) {
            color: $cigi-text-grey;
          }
        }

        .block-guest {
          font-size: 1.625rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.35;
          text-transform: uppercase;

          &.title-case {
            text-transform: none;
          }
        }
      }

      button {
        align-items: center;
        background-color: $bigtech-teal;
        color: $white;
        display: flex;
        font-size: 0.825em;
        justify-content: space-between;
        padding: 0.35rem 1rem;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: $black;

          a {
            color: $bigtech-teal;
          }
        }

        a {
          color: $white;
        }

        i {
          margin-right: 0.2em;
        }
      }

      .simplecast-embed {
        margin: 1em 0 0.5em;
      }

      .date {
        color: $cigi-text-grey;
        font-size: 0.75em;
        margin: 0.5rem 0 1rem;
        text-transform: uppercase;
      }
    }

    .episode-listing {
      background: $cigi-light-grey-bg;
      padding: 3em 0 2em;

      .dropdown-menu {
        button {
          border-top-color: $white;
          padding: 0.5em 1em;

          &::after {
            content: none;
          }
        }
      }

      .season-episodes {
        display: none;

        &.active {
          display: block;
        }

        article {
          border-bottom: solid 1px $cigi-medium-light-grey;
          display: flex;
          padding: 0.875em 0;

          &.unpublished {
            .episode-info {
              h3 {
                color: $cigi-medium-light-grey;

                div {
                  &:not(.block-guest) {
                    color: $cigi-medium-light-grey;
                  }
                }
              }

              p {
                color: $cigi-medium-light-grey;
              }

              .date {
                color: $black;
                margin-bottom: 0.2em;
              }
            }

            .circle {
              span {
                border: solid 1.75px $cigi-medium-light-grey;
                color: $cigi-medium-light-grey;

                &:hover {
                  background-color: transparent;
                  border: solid 1.75px $cigi-medium-light-grey;
                  color: $cigi-medium-light-grey;
                }
              }
            }
          }

          &:last-of-type {
            border-bottom: 0;
          }

          .episode-info {
            @include media-breakpoint-up(md) {
              width: 70%;
            }

            h3 {
              font-size: 1.125em;
              font-style: italic;
              line-height: 1;
              margin-bottom: 0.5rem;

              div {
                &:not(.block-guest) {
                  color: $cigi-text-grey;
                }
              }

              .block-guest {
                font-size: 1.625rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1.35;
                text-transform: uppercase;

                &.title-case {
                  text-transform: none;
                }
              }
            }

            p {
              color: $cigi-text-grey;
              font-style: italic;
              margin: 0.5em 0;
            }

            .date {
              color: $cigi-medium-light-grey;
              font-size: 0.75em;
              margin: 0.5rem 0;
              text-transform: uppercase;
            }
          }

          .circle {
            @include media-breakpoint-up(md) {
              display: flex;
            }
            align-items: center;
            display: none;
            justify-content: center;
            width: 30%;

            a {
              color: $bigtech-teal;
            }

            span {
              border: solid 1.75px $bigtech-teal;
              border-radius: 2.5rem;
              display: inline-block;
              font-size: 1.5em;
              line-height: 100%;
              padding: 1.6rem 1.5rem 1.35rem;
              transition: color ease 0.5s, background-color ease 0.5s;

              &:hover {
                background-color: $bigtech-teal;
                border-color: transparent;
                color: $white;
              }
            }
          }
        }
      }
    }

    .body {
      margin-top: 1.5em;

      h2 {
        color: $bigtech-teal;
      }

      p {
        @include link($color: $black, $hover-color: $bigtech-teal);
      }
    }
  }
}
