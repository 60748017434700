.big-tech-s3-article {
  .about-the-author {
    .author-block {
      @include link($color: $bigtech-s3-red, $hover-color: $cigi-dark-grey);
    }
  }

  .big-tech-recommended {
    margin-bottom: 3em;
    margin-top: 3em;

    .bt-recommended-article {
      border-bottom: 1px solid $cigi-light-grey;
      margin-bottom: 2em;
      padding-bottom: 2em;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  .big-tech-article-hero {
    padding-bottom: 2em;

    h1 {
      font-weight: 400;
      margin-bottom: 0.4em;
    }

    .authors {
      margin-bottom: 0.3em;
      margin-top: 0;

      .block-author {
        @include link($color: $cigi-dark-grey, $hover-color: $bigtech-teal);
        font-size: 1.3em;
      }
    }

    .date {
      color: $cigi-text-grey;
      font-size: 0.75em;
      text-transform: uppercase;
    }

    .social-share-list {
      justify-content: flex-start;
      margin: 0.5em 0 0;
    }

    .topics {
      margin: 0;

      li {
        @include link($color: $cigi-text-grey, $hover-color: $bigtech-teal);
      }
    }
  }

  .about-the-podcast {
    background-color: $cigi-light-grey-bg;
    padding: 2em 0;

    .about-the-podcast-content {
      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .about,
      .subscribe {
        padding: 1em 0;
      }

      .about {
        h2 {
          margin-bottom: 2em;
        }
      }

      .subscribe {
        .subscribe-links {
          @include media-breakpoint-up(sm) {
            align-items: center;
            flex-direction: row;
          }
          @include media-breakpoint-up(md) {
            align-items: flex-start;
            flex-direction: column;
          }
          align-items: flex-start;
          display: flex;
          flex-direction: column;

          a {
            @include media-breakpoint-up(md) {
              margin-right: 0;
              margin-top: 1em;
            }
            margin-right: 2em;
            margin-top: 0;
            max-width: 150px;

            &.apple {
              max-width: 150px;
            }

            &.pocket-casts {
              margin-top: 0;
              width: 130px;
            }

            &.spotify {
              @include media-breakpoint-up(sm) {
                margin-top: 0;
              }
              @include media-breakpoint-up(md) {
                margin-top: 0.8em;
                max-width: 100px;
              }
              margin-top: 0.5em;
              width: 95px;
            }

            svg {
              max-height: 50px;
              width: 100%;
            }
          }
        }
      }

      .spacer {
        @include media-breakpoint-up(md) {
          display: block;
        }
        display: none;

        .slash {
          @include slash($height: 210px);
          margin: 0 80px;
        }
      }
    }
  }

  .stream-read-more-block {
    button {
      color: $bigtech-s3-red;
    }
  }

  .opinion-disclaimer {
    padding-top: 25px;
    position: relative;

    &::before {
      border-top: 6px solid $bigtech-s3-red;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      top: 0;
      width: 30px;
    }
  }
}
