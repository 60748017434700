// Fonts
$primary-font-family: futura-pt, sans-serif;
$accents-font-family: Helvetica, sans-serif;

$sans-regular: 400;
$sans-medium: 500;
$sans-demi: 600;
$sans-bold: 700;
$sans-extrabold: 800;

$serif-regular: 400;
$serif-bold: bold;
$tisa-pro: 'ff-tisa-web-pro', sans-serif;

// Colours
$black: #000;
$white: #fff;
$cigi-primary-colour: #e6023b;
$cigi-primary-colour-dark-bg: #eb023c;
$cigi-primary-colour-grey-bg: #e00239;
$cigi-light-pink: #fce5eb;
$cigi-dark-pink: #bf0332;
$twitter-blue: #55acee;
$facebook-blue: #3b5998;
$rss: #f99b3a;
$unpublished: #ffe6e5;
$color--blue: #2592ec;
$color-event-blue: #0285ff;
$color-dark-blue: #195c94;
$color-event: #edba53;
$color-publication: $cigi-primary-colour;
$color-opinion: #4caba5;
$color-multimedia: #f47b27;
$color-event: #edba53;
$color-media: #8e50bd;
$color-person: #6d6d6d;
$color-topic: #6d6d6d;
$color-activity: #6d6d6d;
$bigtech-teal: #38bf8e;
$dph-green: #5fcf79;
$dph-dark-green: #3A734D;

// Text Greys
$cigi-light-grey-bg: #f6f6f6;
$cigi-light-grey: #e9e9e9;
$cigi-medium-light-grey: #aaa;
$cigi-light-line-grey: #979797;
$cigi-medium-grey: #929292;
$cigi-text-grey: #6d6d6d;
$cigi-text-grey-dark: #4a4a4a;
$cigi-dark-line-grey: #404040;
$cigi-dark-grey: #2f2f2f;
$cigi-darkest-grey: #373737;

// Box Greys
$hover-grey: $cigi-light-grey;
$light-box-grey: #faf8f3;
$body-background: $white;
$previous-body-bg: #f2f0ea;
$dark-box-grey: #dfdfd2;
$divider-grey: #d9d8d2;
$border-box: #d0d0ca;
$border-grey: #4a4a4a;
$whisper-grey: #eee;

// Backgrounds
$dph-background: #2c2c2c;

//addons
$white-opacity-0: rgba(255, 255, 255, 0);
$popup-overlay: rgba(255, 255, 255, 0.6);
$extra-light-box-shadow: rgba(16, 16, 16, 0.15);

$white-alpha-20: rgba(255, 255, 255, 0.2);
$white-alpha-40: rgba(255, 255, 255, 0.4);
$white-alpha-60: rgba(255, 255, 255, 0.6);
$white-alpha-80: rgba(255, 255, 255, 0.8);

$black-alpha-20: rgba(0, 0, 0, 0.2);
$black-alpha-30: rgba(0, 0, 0, 0.3);
$black-alpha-40: rgba(0, 0, 0, 0.4);
$black-alpha-50: rgba(0, 0, 0, 0.5);
$black-alpha-65: rgba(0, 0, 0, 0.65);
$black-alpha-80: rgba(0, 0, 0, 0.8);
$black-alpha-85: rgba(0, 0, 0, 0.85);
